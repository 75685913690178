import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 48,
    // minWidth:"15%",
    // maxWidth: "15%",
    '&.active': {
     backgroundColor: theme.palette.primary.main,
     // backgroundColor: '#2e4167',
      // minWidth:"15%",
      // maxWidth: "15%",
      color: '#ffffff !important',
      // color: '#ffffff !important',
      pointerEvents: 'none',
      '& .list-item-text-primary': {
        color: 'inherit',
      },
      '& .list-item-icon': {
        color: 'inherit',
      },
    },
   
    '& .list-item-icon': {},
    '& .list-item-text': {
      padding: '0 0 0 16px',
    },
    color: '#9e9e9e !important',
    textDecoration: 'none!important',
    '&.dense': {
      //  minWidth:"15%",
      // maxWidth: "15%",
      padding: '8px 12px 8px 12px',
      minHeight: 40,
      '& .list-item-text': {
        padding: '0 0 0 8px',
      },
    },
  },
}));
export default useStyles;
