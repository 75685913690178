/************
 * Author : Mangaleswari P
 * Developed by : Shiva Software Solutions
 * Date : 15-06-2021
 * Descriptions : GudMed change password screen
 * Dependicies : To use @material-ui/core
 * Modified Date :28-06-2021
 * Modified Author Name :Mangaleswari P
 * Modified Fuction :Validation for Email and password
 ************/

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../utility/IntlMessages';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';
import {Fonts} from '../../../shared/constants/AppEnums';
import TextField from '@material-ui/core/TextField';
// for password textfield
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {Icon} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from 'react-redux';
import {onresetPassword} from '../../../redux/actions';
// import constant value
import {passwordRegExp} from '../../../shared/constants/AppConst';
import {
  RESET_USER_PASWD,
  UPDATE_AUTH_USER,
} from '../../../shared/constants/ActionTypes';
import {
  onCognitoUserSignOut,
  onJWTAuthSignout,
  onSignOutAuth0User,
  onSignOutFirebaseUser,
} from '../../../redux/actions';
import {useAuthUser} from '../../utility/AppHooks';
import {AuthType} from '../../../shared/constants/AppEnums';
import IconButton from '@material-ui/core/IconButton';
import ToasterMsg from './ToasterMsg';
const ChangePasswordPopup = ({open, onDeny, title}) => {
  const useStyle = makeStyles({
    formControl: {
      margin: 1,
      minWidth: 120,
    },
    dialogStyle: {
      fontStyle: Fonts.FONTSTYLE,
      position: 'relative',
      padding: 4,
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 400,
        width: '100%',
      },
      '& .MuiDialog-paperWidthSm .MuiBox-root:first-child': {
        padding: 0,
      },
    },
    dialogHeader: {
      backgroundColor: '#2e4167',
      padding: 0,
      alignItems: 'center',
      borderBottom: ['1px solid #afa2a2a6'],
    },
    dialogheadStyle: {
      padding: 5,
      color: '#fff',
    },
    dialogTitle: {
      fontWeight: 'bold',
      marginBottom: 0,
      fontSize: Fonts.TableHeader,
      fontStyle: Fonts.FONTSTYLE,
    },

    textField: {
      fontStyle: Fonts.FONTSTYLE,
      fontSize: 10,
      width: '99%',
      '& .MuiFormLabel-root': {
        fontSize: 14,
      },
      '& .MuiInputLabel-asterisk': {
        color: 'red!important',
      },
      marginTop: 0,
      marginBottom: 0,
      padding: 2,
      marginLeft: 1,
      marginRight: 1,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    btnStyles: {
      textAlign: 'right',
      padding: 10,
    },
    closeBtn: {
      padding: 5,
      textAlign: 'right',
    },
    closeBtnColor: {
      color: '#fff',
    },
    btn: {
      marginLeft: 8,
      color: '#000',
      fontWeight: Fonts.MEDIUM,
    },
    savebtn: {
      backgroundColor: '#2e4167',
      color: '#fff',
      marginLeft: 8,
      fontWeight: Fonts.MEDIUM,
      '&:hover': {
        backgroundColor: '#2e4167',
        color: '#ffffff',
      },
    },
    contentText: {
      color: grey[600],
      padding: 15,
      '& .MuiGrid-item': {
        marginTop: 2,
      },
    },

    userRoot: {
      float: 'right',
    },
  });

  const classes = useStyle();
  const dispatch = useDispatch();
  const user = useAuthUser();
  // get user detail from redux
  const LoginUser = useSelector(({auth}) => auth);
  const resetpaswdsuccess = useSelector(
    ({userManage}) => userManage.resetpaswd,
  );
  const [getsnackbaropen, setsnackbarOpen] = React.useState(false);
  const [msgdata, setmsgdata] = React.useState('');
  const [variant, setvariant] = React.useState('error');
  const [erroroldpaswdtxt, setErroroldpaswdtxt] = React.useState(
    'Please enter old password',
  );
  const [errorconfirmpaswdtxt, setErrorconfirmpaswdtxt] = React.useState(
    'Please enter confirm password',
  );
  const [errornewpaswdtxt, setErrornewpaswdtxt] = React.useState(
    'Please enter new password',
  );
  const [errorValues, setErrorValues] = React.useState({
    password: false,
    newpassword: false,
    confirmpassword: false,
  });
  const [values, setValues] = React.useState({
    password: '',
    newpassword: '',
    confirmpassword: '',
    showPassword: false,
    showPassword1: false,
    showPassword2: false,
  });
  let vialogin = '';
  //Reset password function
  const resetUserPassword = async () => {
    console.log(LoginUser, 'userdata');
    //Validate Input fields
    if (LoginUser && LoginUser.user.paswd) {
      if (values.password === '' || values.password === undefined) {
        setErroroldpaswdtxt('Please enter old password');
        setErrorValues({
          ...errorValues,
          password: true,
          newpassword: false,
          confirmpassword: false,
        });
        return;
      }
      if (!passwordRegExp.test(values.password)) {
        setErroroldpaswdtxt(
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
        );
        setErrorValues({
          ...errorValues,
          password: true,
          newpassword: false,
          confirmpassword: false,
        });
        return;
      }
    }
    if (values.newpassword === '' || values.newpassword === undefined) {
      setErrornewpaswdtxt('Please enter new password');
      setErrorValues({
        ...errorValues,
        password: false,
        newpassword: true,
        confirmpassword: false,
      });
      return;
    }
    if (!passwordRegExp.test(values.newpassword)) {
      setErrornewpaswdtxt(
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
      );
      setErrorValues({
        ...errorValues,
        password: false,
        newpassword: true,
        confirmpassword: false,
      });
      return;
    }
    if (values.confirmpassword === '' || values.confirmpassword === undefined) {
      setErrorconfirmpaswdtxt('Please enter confirm password');
      setErrorValues({
        ...errorValues,
        password: false,
        newpassword: false,
        confirmpassword: true,
      });
      return;
    }
    if (!passwordRegExp.test(values.confirmpassword)) {
      setErrorconfirmpaswdtxt(
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
      );
      setErrorValues({
        ...errorValues,
        password: false,
        newpassword: false,
        confirmpassword: true,
      });
      return;
    }
    if (
      errorValues.password !== true &&
      errorValues.newpassword !== true &&
      errorValues.confirmpassword !== true
    ) {
      if (LoginUser && LoginUser.user.paswd) {
        vialogin = '';
        localStorage.setItem('loginvia', '');
      } else {
        localStorage.setItem('loginvia', 'otp');
        vialogin = 'otp';
      }
      if (values.newpassword === values.confirmpassword) {
        //Call reset user paswd api
        if (LoginUser && LoginUser.user !== null) {
          await dispatch(
            onresetPassword({
              mobileNbr: LoginUser.user.mobileNo,
              patientCode: LoginUser.user.patientCode,
              oldpaswd: values.password,
              newpaswd: values.confirmpassword,
              viasetpaswd: vialogin,
            }),
          );
        }
      } else {
        setErrorconfirmpaswdtxt('New password & Confirm Password Mismatch');
        setErrorValues({
          ...errorValues,
          password: false,
          newpassword: false,
          confirmpassword: true,
        });
        return;
      }
    }
  };

  if (resetpaswdsuccess === 'true') {
    dispatch({
      type: RESET_USER_PASWD,
      payload: 'false',
    });
    setValues({
      ...values,
      password: '',
      newpassword: '',
      confirmpassword: '',
      showPassword: false,
      showPassword1: false,
      showPassword2: false,
    });
    setErrorValues({
      ...errorValues,
      password: false,
      newpassword: false,
      confirmpassword: false,
    });

    if (localStorage.getItem('loginvia') === 'otp') {
      setvariant('success');
      setmsgdata('Password updated successfully');

      dispatch({
        type: UPDATE_AUTH_USER,
        payload: {
          authType: LoginUser.user,
          displayName: LoginUser.user.patname,
          email: LoginUser.user.email,
          mobileNo: LoginUser.user.mobileNo,
          patientCode: LoginUser.user.patientCode,
          paswd: values.newpassword,
          roleId: '1',
          role: 'Patient',
          token: '',
        },
      });
      localStorage.setItem('loginvia', '');
      setsnackbarOpen(true);
      setTimeout(() => {
        // close popup
        onDeny(false);
      }, 2000);
    } else {
      // close popup
      onDeny(false);
      if (user && user.authType === AuthType.AWS_COGNITO) {
        dispatch(onCognitoUserSignOut());
      } else if (user && user.authType === AuthType.FIREBASE) {
        dispatch(onSignOutFirebaseUser());
      } else if (user && user.authType === AuthType.AUTH0) {
        dispatch(onSignOutAuth0User());
      } else if (user && user.authType === AuthType.JWT_AUTH) {
        dispatch(onJWTAuthSignout());
      }
      // history.push('/login');
      // window.location.reload();
    }
  } else if (resetpaswdsuccess === 'incorrect') {
    setvariant('error');
    setmsgdata('Old password is incorrect');
    dispatch({
      type: RESET_USER_PASWD,
      payload: 'false',
    });
    setsnackbarOpen(true);
  }
  // password show and hide functionality
  const handleClickShowPassword = () => {
    setValues({...values, showPassword: !values.showPassword});
  };

  // password show and hide functionality
  const handleClickShowPassword1 = () => {
    setValues({...values, showPassword1: !values.showPassword1});
  };
  // password show and hide functionality
  const handleClickShowPassword2 = () => {
    setValues({...values, showPassword2: !values.showPassword2});
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const closePopup = () => {
    onDeny(false);
    setValues({
      ...values,
      password: '',
      newpassword: '',
      confirmpassword: '',
      showPassword: false,
      showPassword1: false,
      showPassword2: false,
    });
    setErrorValues({
      ...errorValues,
      password: false,
      newpassword: false,
      confirmpassword: false,
    });
  };
  return (
    <Dialog open={open} className={classes.dialogStyle} disableBackdropClick>
      <ToasterMsg
        snackbaropen={getsnackbaropen}
        variantdata={variant}
        msgdata={msgdata}
        onDeny={setsnackbarOpen}
      />
      <Grid container className={classes.dialogHeader}>
        <Grid item xs={8} sm={8} md={6} className={classes.dialogheadStyle}>
          <Box
            component='h4'
            fontWeight={Fonts.REGULAR}
            className={classes.dialogTitle}
            id='userDialogTitle'>
            {title}
          </Box>
        </Grid>
        <Grid item xs={4} sm={4} md={6} className={classes.closeBtn}>
          <IconButton onClick={() => closePopup()}>
            <HighlightOffIcon className={classes.closeBtnColor} />
          </IconButton>
        </Grid>
      </Grid>

      <DialogContent className={classes.contentText} id='dialogDescription'>
        {LoginUser &&
        LoginUser.user.paswd &&
        LoginUser.user.displayName !== '' ? (
          <Box className={classes.userRoot} display='flex'>
            <Icon>account_circle</Icon>
            <Box ml={3} style={{marginTop: '2px'}}>
              {LoginUser.user.displayName}
            </Box>
          </Box>
        ) : (
          ''
        )}
        {LoginUser && LoginUser.user.paswd ? (
          <Grid item xs={12} md={12}>
            <TextField
              required
              id='password'
              value={values.pwd}
              className={classes.textField}
              onChange={(event) => {
                setValues({...values, password: event.target.value});
              }}
              name='password'
              label='Old Password'
              onBlur={(event) => {
                // password validation
                if (event.target.value === '') {
                  setErroroldpaswdtxt('Please enter old password');
                  setErrorValues({...errorValues, password: true});
                } else if (!passwordRegExp.test(event.target.value)) {
                  setErroroldpaswdtxt(
                    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
                  );
                  setErrorValues({...errorValues, password: true});
                } else {
                  setErrorValues({...errorValues, password: false});
                }
              }}
              type={values.showPassword ? 'text' : 'password'}
              inputProps={{minLength: 8, maxLength: 20}}
              InputProps={{
                minLength: 8,
                maxLength: 20,
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      edge='end'
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={
                errorValues.password === true ? erroroldpaswdtxt : ' '
              }
              error={errorValues.password === true}
            />
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12} md={12}>
          <TextField
            required
            id='newpassword'
            value={values.newpassword}
            className={classes.textField}
            onChange={(event) => {
              setValues({...values, newpassword: event.target.value});
            }}
            name='newpassword'
            label='New Password'
            onBlur={(event) => {
              // password validation
              if (event.target.value === '') {
                setErrornewpaswdtxt('Please enter new password');
                setErrorValues({...errorValues, newpassword: true});
              } else if (!passwordRegExp.test(event.target.value)) {
                setErrornewpaswdtxt(
                  'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
                );
                setErrorValues({...errorValues, newpassword: true});
              } else {
                setErrorValues({...errorValues, newpassword: false});
              }
            }}
            type={values.showPassword1 ? 'text' : 'password'}
            inputProps={{minLength: 8, maxLength: 20}}
            InputProps={{
              minLength: 8,
              maxLength: 20,
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword}>
                    {values.showPassword1 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText={
              errorValues.newpassword === true ? errornewpaswdtxt : ' '
            }
            error={errorValues.newpassword === true}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            required
            id='confirmpassword'
            value={values.confirmpassword}
            className={classes.textField}
            onChange={(event) => {
              setValues({...values, confirmpassword: event.target.value});
            }}
            name='confirmpassword'
            label='Confirm Password'
            onBlur={(event) => {
              // password validation
              if (event.target.value === '') {
                setErrorconfirmpaswdtxt('Please enter confirm password');
                setErrorValues({...errorValues, confirmpassword: true});
              } else if (!passwordRegExp.test(event.target.value)) {
                setErrorconfirmpaswdtxt(
                  'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
                );
                setErrorValues({...errorValues, confirmpassword: true});
              } else {
                setErrorValues({...errorValues, confirmpassword: false});
              }
            }}
            type={values.showPassword2 ? 'text' : 'password'}
            inputProps={{minLength: 8, maxLength: 20}}
            InputProps={{
              minLength: 8,
              maxLength: 20,
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword}>
                    {values.showPassword2 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText={
              errorValues.confirmpassword === true ? errorconfirmpaswdtxt : ' '
            }
            error={errorValues.confirmpassword === true}
          />
        </Grid>
      </DialogContent>
      <Box pt={2} className={classes.btnStyles}>
        {/* <Button
          variant='outlined'
          className={classes.btn}
          onClick={() => closePopup()}>
          <IntlMessages id='common.cancel' />
        </Button> */}
        <Button
          onClick={resetUserPassword}
          className={classes.savebtn}
          variant='outlined'>
          <IntlMessages id='common.save' />
        </Button>
      </Box>
    </Dialog>
  );
};

export default ChangePasswordPopup;

ChangePasswordPopup.propTypes = {
  //  dialogTitle: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onDeny: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
};
