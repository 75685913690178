/************
 * Developed by : Shiva Software Solutions
 * File Name : myprofile.js
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers
 ************/
import {
  STATE_MASTER,
  PATIENT_PROFILE,
  SAVE_PROFILES,
} from '../../shared/constants/ActionTypes';

const initialState = {
  stateMaster: [],
  profileData: {},
  saveProfile: false,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATE_MASTER:
      return {
        ...state,
        stateMaster: action.payload,
      };
    case PATIENT_PROFILE:
      return {
        ...state,
        profileData: action.payload,
      };
    case SAVE_PROFILES:
      return {
        ...state,
        saveProfile: action.payload,
      };
    default:
      return state;
  }
};
export default profileReducer;
