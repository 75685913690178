import {
  MAPPING_PATIENT_LIST,
  MAPPING_SUCCESS,
  DROP_PATIENT_LIST,
  DROP_PATIENT_RELATION,
  PATIENT_RELATION_SUCCESS,
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  mapping_patients: [],
  mapping_success: '',
  drop_patients: [],
  patient_relations: [],
  patient_relation_success: '',
};

const MappingPatientReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MAPPING_PATIENT_LIST:
      return {
        ...state,
        mapping_patients: action.payload,
      };
    case DROP_PATIENT_LIST:
      return {
        ...state,
        drop_patients: action.payload,
      };
    case MAPPING_SUCCESS:
      return {
        ...state,
        mapping_success: action.payload,
      };
    case DROP_PATIENT_RELATION:
      return {
        ...state,
        patient_relations: action.payload,
      };
    case PATIENT_RELATION_SUCCESS:
      return {
        ...state,
        patient_relation_success: action.payload,
      };

    default:
      return state;
  }
};
export default MappingPatientReducer;
