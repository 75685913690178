/************

 * Developed by : Shiva Software Solutions
 * Date : 14-06-2021
 * Descriptions : Route for dahboard page

 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const dashBoardConfigs = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/dashboard',
        component: React.lazy(() => import('./DashboardPage/Tabs/index')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/my-profile',
        component: React.lazy(() => import('../dashboard/MyAccount/index')),
      },
    ],
  },
];
