/************
 * Author : Mangaleswari P
 * Developed by : Shiva Software Solutions
 * Date : 28-06-2021
 * Descriptions : Route Navigation
 * Dependicies : To use @material-ui/core
 * Modified Date :28-06-2021
 * Modified Author Name :Mangaleswari P
 * Modified Fuction :for session idle timeout(inprogress)
 ************/
import React, {useContext, useRef} from 'react';
import {renderRoutes} from 'react-router-config';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Switch, useLocation} from 'react-router-dom';
import {AppSuspense} from '../../index';
import routes from '../../../modules';
import Scrollbar from '../Scrollbar';
import AppContext from '../../utility/AppContext';
import PropTypes from 'prop-types';
import AppFooter from '../AppLayout/AppFooter';
import Box from '@material-ui/core/Box';
import {RouteTransition} from '../../../shared/constants/AppEnums';
import AppErrorBoundary from '../AppErrorBoundary';
import IdleTimer from 'react-idle-timer';
import {useDispatch} from 'react-redux';
import {useAuthUser} from '../../utility/AppHooks';
import {AuthType} from '../../../shared/constants/AppEnums';
import {onJWTAuthSignout} from '../../../redux/actions';
const TransitionWrapper = ({children}) => {
  const {rtAnim} = useContext(AppContext);
  const location = useLocation();
  // console.log(location.pathname);
  if (rtAnim === RouteTransition.NONE) {
    return <>{children}</>;
  }
  const currentKey = location.pathname.split('/')[1] || '/';
  console.log(currentKey);
  return (
    <TransitionGroup appear enter exit>
      <CSSTransition
        key={currentKey}
        timeout={{enter: 300, exit: 300}}
        classNames={rtAnim}>
        <Switch location={location}>{children}</Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

TransitionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
const ContentView = () => {
  const dispatch = useDispatch();
  const user = useAuthUser();
  const idleTimer = useRef();
  const [idletime, setIdleTime] = React.useState({
    timeout: 18000 * 10 * 1,
    showModal: false,
    userLoggedIn: false,
    isTimedOut: false,
  });

  const onAction = (e) => {
    // console.log('user did something', e);
    setIdleTime({...idletime, isTimedOut: false});
  };

  const onActive = (e) => {
    // console.log('user is active', e);
    // this.setState({isTimedOut: false})
    setIdleTime({...idletime, isTimedOut: false});
  };

  const onIdle = (e) => {
    console.log('user is idle', e);
    // const isTimedOut = idletime.isTimedOut
    if (idletime.isTimedOut) {
      // alert("timedd pout ")

      if (user && user.authType === AuthType.JWT_AUTH) {
        dispatch(onJWTAuthSignout());
      }
    } else {
      idleTimer.current.reset();
      setIdleTime({...idletime, isTimedOut: true});
    }
  };
  return (
    <Scrollbar>
      <Box
        display='flex'
        flex={1}
        flexDirection='column'
        className='main-content-view'>
        <AppSuspense>
          <AppErrorBoundary>
            <IdleTimer
              ref={idleTimer}
              element={document}
              onActive={onActive}
              onIdle={onIdle}
              onAction={onAction}
              debounce={250}
              timeout={idletime.timeout}
            />
            <TransitionWrapper>{renderRoutes(routes)}</TransitionWrapper>
          </AppErrorBoundary>
        </AppSuspense>
      </Box>
      <AppFooter />
    </Scrollbar>
  );
};

export default ContentView;
