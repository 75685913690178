/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Dashboard api calling
 ************/
import {
  FETCH_ERROR,
  PATIENT_PROFILE,
  STATE_MASTER,
  SAVE_PROFILES,
  OTP_SIGNUP_ERROR,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'myprofile.js';

export const onGetStatemaster = (body) => {
  return (dispatch) => {
    jwtAxios
      .post('v1/patMgmt/mastersjwt', body)
      .then((data) => {
        body['jwtToken'] = data.data.body['token'];
        jwtAxios
          .post('v1/patMgmt/masters', body)
          .then((data) => {
            console.log(data.data.body.stateList, 'statedata');
            dispatch({
              type: STATE_MASTER,
              payload: data.data.body.stateList,
            });
          })
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetStatemaster',
                '0',
                error,
              ),
            );
          });
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetStatemaster',
            '0',
            error,
          ),
        );
      });
  };
};

//get profile data
export const onGetPatientData = (body) => {
  return (dispatch) => {
    jwtAxios
      .post('v1/patMgmt/getPatientDataJwt', body)
      .then((data) => {
        body['jwtToken'] = data.data.body['token'];
        jwtAxios
          .post('v1/patMgmt/getPatientData', body)
          .then((data) => {
            console.log(data.data.body, 'get patient datas');
            var patientpersonal = {
              ...data.data.body.personal,
              ...data.data.body.addressData,
            };
            dispatch({
              type: PATIENT_PROFILE,
              payload: patientpersonal,
            });
          })
          .catch((error) => {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetPatientData',
                '0',
                error,
              ),
            );
          });
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetPatientData',
            '0',
            error,
          ),
        );
      });
  };
};

//save profile data
export const savePatientProfiles = (body) => {
  return (dispatch) => {
    console.log(body);
    jwtAxios
      .post('v1/patMgmt/saveProfileJwt', body)
      .then((data) => {
        if (data.data.message && data.data.message.includes('Error')) {
          dispatch({
            type: OTP_SIGNUP_ERROR,
            payload: data.data.message.split(':')[2],
          });
          return;
        }
        if (data.data.body['token']) {
          body['jwtToken'] = data.data.body['token'];
          jwtAxios
            .post('v1/patMgmt/saveProfile', body)
            .then((data) => {
              dispatch({
                type: SAVE_PROFILES,
                payload: true,
              });
            })
            .catch((error) => {
              dispatch({type: FETCH_ERROR, payload: error.message});
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'savePatientProfiles',
                  '0',
                  error,
                ),
              );
            });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'savePatientProfiles',
            '0',
            error,
          ),
        );
      });
  };
};
