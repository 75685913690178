/************
 * Developed by : Shiva Software Solutions
 * Date : 25-02-2022
 * Descriptions : Route page for erx upload page
 * Dependicies : To use @material-ui/core,react-router-dom,formik
 ************/
import React from 'react';

export const eRxRouteConfig = [
  {
    //Redirect to erx page
    routes: [
      {
        path: '/uploadprescription',
        component: React.lazy(() => import('./eRx/index')),
      },
    ],
  },
];
