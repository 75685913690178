/************
 * Developed by : Shiva Software Solutions
 * Date : 15-06-2021
 * Descriptions : Route configuration files
 ************/

import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';
import {dashBoardConfigs} from './dashboard';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import {eRxDetailsConfigs} from './eRxDetails';
import {covidVitalsConfig} from './covidVitals';
import {reportsConfig} from './reports';
import {eRxRouteConfig} from './eRxUpload';
import {patienteRxRouteConfig} from './patientERX';
import {generateHealthIDConfig} from './generateHealthID';
import {appointmentsConfig} from './appointments';
import {mergedPatientConfig} from './mergedPatient';
import {oneMgConfig} from './oneMG';
import {eRxUploadPDConfig} from './eRxUploadPrivateDoctors';
import {eRxDemoPrescriptionRouteConfig} from './eRxUploadDemoPrescription';

const routeConfigs = [
  ...authRouteConfig,
  ...dashBoardConfigs,
  ...eRxDetailsConfigs,
  ...reportsConfig,
  ...covidVitalsConfig,
  ...eRxRouteConfig,
  ...patienteRxRouteConfig,
  ...generateHealthIDConfig,
  ...appointmentsConfig,
  ...mergedPatientConfig,
  ...oneMgConfig,
  ...eRxUploadPDConfig,
  ...eRxDemoPrescriptionRouteConfig,
];

const routes = [
  ...createRoutes(routeConfigs),

  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
