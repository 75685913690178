import React, {useContext} from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AppContext from '../../../@crema/utility/AppContext';
import {ThemeMode} from '../../constants/AppEnums';
import Hidden from '@material-ui/core/Hidden';
import {useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
const AppLogo = () => {
  const {themeMode} = useContext(AppContext);
  const useStyles = makeStyles(() => ({
    logoRoot: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
    },
    logo: {
      //height: 36,
      height: 'auto',
      marginRight: 10,
    },
    heading: {
      color: '#2e4167',
      fontSize: '18px',
      fontWeight: 'bold',
      '@media (max-width: 767px)': {
        display: 'none',
      },
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  //Go to dahboard
  const goToDashboard = async () => {
    history.push('/');
  };
  return (
    <Box className={classes.logoRoot} onClick={() => goToDashboard()}>
      <Hidden smUp>
        <img
          className={classes.logo}
          src={
            // themeMode === ThemeMode.DARK
            //   ? '/assets/images/logo-white.png'
            //   : '/assets/images/logo.png'
            themeMode === ThemeMode.DARK
              ? '/assets/images/gudmed-top-logo.png'
              : '/assets/images/gudmed-top-logo.png'
          }
          alt='gudmed-logo'
        />
      </Hidden>

      <Hidden xsDown>
        <img
          className={classes.logo}
          src={
            // themeMode === ThemeMode.DARK
            //   ? '/assets/images/logo-white-with-name.png'
            //   : '/assets/images/logo-with-name.png'
            themeMode === ThemeMode.DARK
              ? '/assets/images/gudmed-top-logo.png'
              : '/assets/images/gudmed-top-logo.png'
          }
          alt='gudmed-logo'
        />
      </Hidden>
      <Typography className={classes.heading} component='h2'>
        Gudmed Patient Portal{' '}
      </Typography>
    </Box>
  );
};

export default AppLogo;
