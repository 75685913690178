/************
 * Developed by : Shiva Software Solutions
 * Date : 21-12-2021
 * Descriptions : Call api response using reducers for  master data
 ************/
import {INSERT_OWN_ERX_SUCCESS} from '../../shared/constants/ActionTypes';
//Inital state
const initialState = {
  owneRx_succes: '',
};
//Doctor List
const uploadeRxReducer = (state = initialState, action) => {
  switch (action.type) {
    //symptom master list
    case INSERT_OWN_ERX_SUCCESS:
      return {
        ...state,
        owneRx_succes: action.payload,
      };
    default:
      return state;
  }
};
export default uploadeRxReducer;
