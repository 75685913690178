import React, {useContext, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import {useAuthUser} from '../../utility/AppHooks';
import AppContext from '../../utility/AppContext';
import {makeStyles} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import {orange} from '@material-ui/core/colors';
import {Fonts} from '../../../shared/constants/AppEnums';
import Hidden from '@material-ui/core/Hidden';
import ChangePasswordPopup from './ChangepasswordPopup';
// import confirmation dialog
import ConfirmationDialog from './confirmationDialog';
import {useHistory} from 'react-router-dom';
// for style
const useStyles = makeStyles((theme) => {
  return {
    userRoot: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      position: 'relative',
    },
    avatar: {
      fontSize: 24,
      backgroundColor: orange[500],
    },
    autowidht: {
      width: 'auto',
      height: 'auto',
      marginLeft: 6,
    },
    userInfo: {
      width: 'calc(100% - 75px)',
    },
    pointer: {
      cursor: 'pointer',
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      fontSize: 15,
      textTransform: 'capitalize',
      fontWeight: Fonts.MEDIUM,
      color: (props) =>
        props.bgType === 'colored' ? 'white' : theme.palette.text.primary,
    },

    outerBox: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
    },
  };
});

const HorUserInfo = ({bgType = 'colored'}) => {
  const [changePassword, setChangePasswordPopup] = useState(false);
  const {themeMode} = useContext(AppContext);
  const [openpopup, setopenpopup] = useState(false);

  const user = useAuthUser();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // changepassword open popup event
  const openChangepasswordPopup = () => {
    setAnchorEl(null);
    setChangePasswordPopup(true);
  };

  // logout open popup event
  const logoutClick = () => {
    setAnchorEl(null);
    setopenpopup(true);
  };
  const onGoToMyAccount = () => {
    setAnchorEl(null);
    history.push('/my-profile');
  };

  const classes = useStyles({themeMode, bgType});

  return (
    <Box py={2} pl={{xs: 2, sm: 3, md: 5}}>
      <Box className={classes.outerBox}>
        <Box className={classes.userRoot} display='flex' onClick={handleClick}>
          {/* {user.photoURL ? (
          <Avatar className={classes.avatar} src={user.photoURL} />
        ) : (
          <Avatar className={classes.avatar}>{getUserAvatar()}</Avatar>
        )} */}

          <Avatar
            src='/assets/images/profile-user.png'
            className={classes.autowidht}
          />

          <Hidden mdDown>
            <Box ml={3} className={classes.userName}>
              {/* display name check */}
              {user.displayName ? user.displayName : user.email}
              {/* <Box fontSize={13} fontWeight={Fonts.LIGHT}>
                {user.role}
              </Box> */}
            </Box>
          </Hidden>
        </Box>
      </Box>
      <Box className={classes.userInfo}>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          {/* <MenuItem
            onClose={handleClose}
            onClick={() => onGoToMyAccount()}>
            My Account
          </MenuItem> */}
          <MenuItem onClose={handleClose} onClick={() => onGoToMyAccount()}>
            My Profile
          </MenuItem>
          <MenuItem
            onClose={handleClose}
            onClick={() => openChangepasswordPopup()}>
            {user.paswd ? 'Change Password' : 'Set Password'}
          </MenuItem>

          <MenuItem
            onClick={() => {
              logoutClick();
            }}>
            Logout
          </MenuItem>
        </Menu>
        <ChangePasswordPopup
          open={changePassword}
          onDeny={setChangePasswordPopup}
          title={user.paswd ? 'Change Password' : 'Set Password'}
        />
        <ConfirmationDialog opendialog={openpopup} onDeny={setopenpopup} />
      </Box>
    </Box>
  );
};

export default HorUserInfo;
