/************
 * Developed by : Shiva Software Solutions
 * Date : 18-06-2021
 * Descriptions : Route for eRx Details
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';
//Route config for eRx details
export const appointmentsConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/appointments',
        component: React.lazy(() => import('./appointments')),
      },
    ],
  },
];
